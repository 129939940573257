<template>
  <div>
    <el-card>
      <div class="menu-box">
        <h2>核算方式管理</h2>
        <div class="box-bd">
          <!-- 头 -->
          <el-row :gutter="20">
            <el-col :span="4">
              <el-button type="primary" @click="pAdd()">添加核算方式</el-button>
            </el-col>
            <el-col :span="4">
              <el-button type="warning" @click="dialogLog.state = true"
                >查看日志</el-button
              >
            </el-col>
          </el-row>
          <!-- 表格 -->
          <el-table
            v-loading="tableLoading"
            class="task-table"
            :data="tableData"
            show-checkbox
            :expand-on-click-node="false"
            row-key="id"
            :tree-props="{ children: 'son', hasChildren: 'hasChildren' }"
          >
            <el-table-column prop="name" label="核算方式名称">
            </el-table-column>

            <el-table-column prop="state" label="核算方式状态">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.status === 1">
                  启用
                </el-tag>
                <el-tag type="warning" v-if="scope.row.status === 0">
                  禁用
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column width="180" label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="warning"
                  icon="el-icon-edit"
                  @click="handleEdit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="remove(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-card>
    <!-- 添加/删除对话框 -->
    <el-dialog
      v-dialogDrag
      :title="title"
      :visible.sync="dialogVisible"
      :before-close="formClose"
    >
      <el-form :rules="formRules" ref="cateFormRef" :model="form">
        <el-form-item
          prop="name"
          label="核算方式名称"
          :label-width="formLabelWidth"
        >
          <el-input @change="handleChange" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item prop="order" label="排序" :label-width="formLabelWidth">
          <el-input @change="handleChange" v-model="form.order"></el-input>
        </el-form-item>
        <el-form-item
          v-if="addOrEdit === 2"
          prop="status"
          label="状态"
          :label-width="formLabelWidth"
        >
          <el-switch
            v-model="form.status"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
          />
        </el-form-item>
      </el-form>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelAE()">取 消</el-button>
        <el-button type="primary" @click="confirmAE()">确 定</el-button>
      </div>
    </el-dialog>
    <all-log :dialogLog="dialogLog" />
  </div>
</template>

<script>
import { getList, add, edit, del, info } from '@/api/psi/accountingmethods.js'
export default {
  data() {
    return {
      isEdit: false,
      tableLoading: false,
      warningText: '',

      dialogVisible: false,
      changeDialogVisible: false,
      formRules: {
        name: {
          required: true,
          message: '请输入核算方式名称',
          trigger: 'blur',
        },
        order: { required: true, message: '请输入核算方式列表排序' },
      },
      title: '添加核算方式',
      addOrEdit: 1,
      formLabelWidth: '120px',
      optionsTask: [],
      optionPropsTask: {
        value: 'id',
        label: 'name',
        children: 'son',
        disabled: 'state',
      },
      form: {
        name: '',
        order: '',
      },
      tableData: [],
      dialogLog: {
        state: false,
      },
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      this.tableLoading = true
      const { data: res } = await getList()
      console.log(res)
      if (res.code === 1) {
        this.tableData = res.data
      } else {
        this.$message.error('获取数据失败！')
      }
      this.tableLoading = false
    },
    // 添加/编辑商品对话框关闭事件
    formClose() {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    handleChange() {
      this.isEdit = true
    },
    handleChangeClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.changeDialogVisible = false
          this.getList()
          done()
        })
        .catch((_) => {})
    },
    // 取消
    cancelAE() {
      this.title = '添加核算方式'
      this.isEdit = false
      this.addOrEdit = 1
      this.form = {
        name: '',
        order: '',
      }
      setTimeout(() => {
        this.$refs.cateFormRef.clearValidate()
      }, 30)
      this.dialogVisible = false
    },
    // 状态修改
    async changeOpen(row) {
      console.log(row)
      this.row = row
      this.changeDialogVisible = true
      if (row.status === 0) {
        // 即将禁用
        this.warningText =
          '此操作将隐藏该入库商品分类, 禁用所有功能不可用，并计入操作日志，是否继续?'
      } else if (row.status === 1) {
        // 即将开启
        this.warningText =
          '此操作将显示 该入库商品分类,启用后开启所有功能，并计入操作日志，是否继续?'
      }
    },
    // 新增打开
    pAdd(row) {
      console.log(row)

      this.dialogVisible = true
    },
    // 编辑打开
    async handleEdit(row) {
      this.dialogVisible = true
      this.title = '编辑核算方式'
      this.addOrEdit = 2
      const { data: res } = await info({
        type_id: row.id,
      })

      this.form = res.data
      this.form.type_id = row.id
    },
    // 删除
    remove(row) {
      this.$confirm('确认删除？')
        .then(async (_) => {
          const { data: res } = await del({
            type_id: row.id,
          })
          if (res.code === 1) {
            this.$message.success('删除成功！')
            this.getList()
          } else {
            this.$message.error(res.message)
          }
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    confirmAE() {
      this.$refs.cateFormRef.validate(async (valid) => {
        if (valid) {
          if (this.addOrEdit === 1) {
            // 添加
            const { data: res } = await add(this.form)
            if (res.code === 1) {
              this.$message.success('添加成功')
              this.getList()
              this.cancelAE()
            } else {
              this.$message.error(res.message)
            }
          } else {
            // 编辑

            if (this.isEdit === true) {
              const { data: res } = await edit(this.form)
              if (res.code === 1) {
                this.$message.success('编辑成功')
                this.cancelAE()
                this.getList()
              } else {
                this.$message.error(res.message)
              }
            } else {
              this.$message.info('没有修改任何数据')
            }
          }
        }
      })
    },
  },
}
</script>

<style scoped>
.content {
  display: flex;
  align-items: center;
}
.content .warning-icon {
  width: 44px;
  height: 32px;
  margin-right: 10px;
  background: url('../../../../../assets/exclamationmark.png') no-repeat;
}
</style>
