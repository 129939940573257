import { post } from '@/utils/request'

//获取目录
export function getList(data) {
    return post('/admin/Settle_Type/typeList', data)
}
export function getList1(data) {
    return post('/admin/Settle_Type/typeList', data)
}
//修改
export function edit(data) {
    return post('/admin/Settle_Type/editType', data)
}
//详情
export function info(data) {
    return post('/admin/Settle_Type/typeInfo', data)
}
//添加
export function add(data) {
    return post('/admin/Settle_Type/addType', data)
}
//删除
export function del(data) {
    return post('/admin/Settle_Type/delType', data)
}